<template>
    <div class="container">
        <div class="right_cont">
            <!-- <div class="title flex_center_start_box">
                <div class="title_txt flex_center">
                    <img src="../../assets/img/detail/title_ic4.png" />
                    HISTORY AND EVENTS
                </div>
            </div> -->
            <div class="his_box">
                <div class="head-title" v-if="parentTablenum1.length > 0">ID Numbers</div>
                <div class="persion-table" v-if="parentTablenum1.length > 0">
                    <el-table ref="treeTable" :data="Numbersable" border row-key="aaa_id"  style="width: 100%" >
                        <el-table-column label="Type" prop="type" align="left" show-overflow-tooltip :resizable="false" :header-cell-style="headerCellStyle"></el-table-column>

                        <el-table-column label="Number" prop="value" align="left" show-overflow-tooltip :resizable="false" />

                        <el-table-column label="Notes" prop="comments" align="left" show-overflow-tooltip :resizable="false" />

                        <!-- 空数据状态的插槽 -->
                        <template slot="empty">
                            <div style="text-align: left">No Date</div>
                        </template>
                    </el-table>
                    <div class="page_i_box">
                        <Page :totalFont="true" :total="page1.total" @onPageChange="onPageChangeList1" @currtentPageChange="currtentPageChange1"></Page>
                        <div class="search_rsult_txt">{{ page1.pageNo }}-{{ page1.pageSize }} of over {{ page1.total | FilterNum }} results</div>
                    </div>
                </div>
            </div>
          
        </div>
    </div>
</template>
<script>
import { searchDetailsById } from '@/api/searchApi.js';
import Page from '@/components/page';

export default {
    components: { Page },
    data() {
        return {
          page1: {
                pageNo: 1,
                pageSize: 10,
                total: 0,
            },
            activities: [],
            Numbersable:[],
            tableListnum: [],
            parentTablenum1: [],
        };
    },
    computed: {
  headerCellStyle() {
    return {
      backgroundColor: 'red', // 设置背景色
      // 其他样式属性
    };
  },
},

    created() {
        this.getdetail();
    },
    methods: {

      pageListid() {
            // console.log(this.parentTable);

            this.Numbersable = this.parentTablenum1.filter((item, index) => index < this.page1.pageNo * this.page1.pageSize && index >= this.page1.pageSize * (this.page1.pageNo - 1));
            this.page1.total = this.parentTablenum1.length;
        },
        onPageChangeList1(pageNo) {
            // this.pageNo += 1
            console.log(`pageNo`, pageNo);
            this.page1.pageNo = pageNo;
            this.pageListid();
        },
        currtentPageChange1(pageSize) {
            // this.pageNo += 1
            console.log(`pageNo`, pageSize);
            this.page1.pageSize = pageSize;
            // this.init()
            this.pageListid();
        },
        searchPersonByNameid() {
            let list = this.tableListnum;
            let keyWord = this.searchTblName;
            var arr = [];
            if (keyWord == '') {
                this.Numbersable = list;
                this.parentTablenum1 = list;
            } else {
                // console.log(`list`, list);
                console.log(keyWord);
                let that = this;
                arr = list.filter(item => {
                    if (that.$route.query.type == 'Person') {
                        // console.log(`item`, item);
                        return item?.name_detail?.surname?.toUpperCase().indexOf(keyWord.toUpperCase()) !== -1 || item?.name_detail?.first_name?.toUpperCase().indexOf(keyWord.toUpperCase()) !== -1;
                    } else {
                        return item?.name_detail?.name?.toUpperCase().indexOf(keyWord.toUpperCase()) !== -1;
                    }
                });
                console.log(arr);
                this.parentTablenum1 = arr;
            }
            this.pageListid();
        },
        getdetail() {
            searchDetailsById({ id: this.$route.query.id }).then(res => {
                console.log(`res`, res);
                let nums = [];
                let nums1 = [];
                // this.Relationstable=
                // this.page.total=this.Relationstable.length
            
                nums = res.data.attributes.person?.identification_details ? res.data.attributes.person?.identification_details : [];
                nums1 = res.data.attributes.watchlist?.identification_details ? res.data.attributes.watchlist?.identification_details : [];
                nums = nums.concat(nums1);
                console.log(`nums`, nums);
                this.parentTablenum1 = res.data.attributes.entity?.identification_details ? res.data.attributes.entity?.identification_details.concat(nums) : [].concat(nums);
                this.tableListnum = res.data.attributes.entity?.identification_details ? res.data.attributes.entity?.identification_details.concat(nums) : [];
                this.pageListid();
        
             
            });
        },
    },
};
</script>
<style scoped>
.cont_m {
    margin-top: 30px;
    margin-bottom: 80px;
}
.right_cont {
    margin-top: 60px;
}
.title {
    margin-bottom: 20px;
}
.title_txt {
    font-size: 16px;
    font-family: 'Arial Bold';
}
.title_txt img {
    width: 20px;
    margin-right: 8px;
}
.pageBox {
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
}
.his_box {
    width: 100%;
    padding: 30px 30px 30px;
    background: #ffffff;
    box-shadow: 0px 2px 6px 1px rgba(10, 33, 57, 0.14);
    border-radius: 14px 14px 0px 0px;
}
.his_box /deep/ .el-timeline-item__wrapper {
    display: flex;
    padding-left: 14px;
}
.his_box /deep/ .el-timeline-item__node--normal {
    width: 8px;
    height: 8px;
    left: 1px;
}
.his_box /deep/ .el-timeline-item__node {
    background-color: #ff7600;
}
.his_box /deep/ .el-timeline-item__tail {
    border-left: 2px dashed #8497ab;
}
.his_box /deep/ .el-timeline-item__timestamp.is-top {
    font-size: 16px;
    color: #8497ab;
    padding-top: 0;
}
.his_box /deep/ .el-timeline-item__content {
    padding-bottom: 24px;
    font-size: 16px;
    color: #022955;
    border-bottom: 1px solid #e7eaed;
    margin-left: 20px;
    flex: 1;
    line-height: 26px;
    margin-top: -5px;
}
.head-title {
    /* color: #022955; */
    font-size: 16px;
    line-height: 35px;
    /* margin-top: 20px; */
    color: #ff7600;
    /* padding-left: 20px; */
    font-weight: 700;
    font-family: 'element-icons';
}
.his_box /deep/ .el-table th.el-table__cell{
  background: #F2F5F9;
}
</style>
